<i18n src="@i18n/drone/part.th.yaml" locale="th" lang="yaml"></i18n>

<template>
<a-card
	:bordered="false" class="part-card mycard mycard-with-details"
	:loading="loading">
	<a-button
		v-if="canUpdate"
		class="mycard-button-action"
		shape="circle"
		icon="edit"
		@click="handleUpdateClick" />
	<div class="mycard-content">
		<div class="mycard-header">
			<ImageLoader
				class="mycard-image"
				:src="part.image_url"
				border-type="round">
				<a-icon type="deployment-unit" />
			</ImageLoader>
		</div>
		<ul class="mycard-details">
			<li>
				<span class="label">{{$t('part.field.sku')}}</span>
				<span class="value">{{part.sku}}</span>
			</li>
			<li>
				<span class="label">{{$t('part.field.name')}}</span>
				<span class="value">{{part.name}}</span>
			</li>
			<li>
				<span class="label">{{$t('part.field.name_en')}}</span>
				<span class="value">{{part.name_en | emptyCheck}}</span>
			</li>
			<li>
				<span class="label">{{$t('part.field.part_type')}}</span>
				<span class="value">{{$tenum('part_type',part.part_type)}}</span>
			</li>
		</ul>
	</div>
</a-card>
</template>

<script>
import ImageLoader from "@components/common/ImageLoader.vue"
export default {
	components : {
		ImageLoader
	} ,
	props : {
		loading : {
			type : Boolean,
			default: false
		} ,
		part : {
			type : null,
			default : () => []
		} ,
		showUpdate : {
			type: Boolean,
			default: false
		}
	} ,
	computed : {
		canUpdate() {
			return this.showUpdate && this.part.id && this.$authorize('update', 'part')
		}
	} ,
	methods : {
		handleUpdateClick() {
			this.$open({name:'part/update',params : {id : this.part.id}})
		}
	}
}
</script>
