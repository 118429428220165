<i18n locale="en" lang="yaml">
 page.title : "SKU {name} :: Part Information"
</i18n>
<i18n locale="th" lang="yaml" >
page.title : "SKU {name} :: ข้อมูลอะไหล่"
tab.part_info : "ข้อมูลอะไหล่"
tab.part_lots : "In Lot"
tab.part_usages : "การใช้งาน"
</i18n>
<template>
	<div class="page page-padding">
		<div class="mylayout-left-right responsive">
			<div class="mylayout-left">
				<PartCard
					:loading="pageLoading"
					:part="part"
					:show-update="!$route.meta.hideTab" />
			</div>

			<div class="mylayout-right">
				<template v-if="!$route.meta.hideTab">
					<a-menu
						mode="horizontal"
						type="inner"
						class="mytab-menu"
						:selected-keys="selectedMenu">
						<a-menu-item key="part/base">
							<my-router-link name="part/base" :param-value="$route.params.id">
								{{$t('tab.part_info')}}
							</my-router-link>
						</a-menu-item>
						<a-menu-item key="part/usages">
							<my-router-link name="part/usages" :param-value="$route.params.id">
								{{$t('tab.part_usages')}}
							</my-router-link>
						</a-menu-item>
						<a-menu-item key="part/lots">
							<my-router-link name="part/lots" :param-value="$route.params.id">
								{{$t('tab.part_lots')}}
							</my-router-link>
						</a-menu-item>
					</a-menu>
					<div class="mytab-content">
						<keep-alive>
							<router-view :part="part"></router-view>
						</keep-alive>
					</div>
				</template>
				<template v-else>
					<router-view :part="part"></router-view>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import LoginInfoMixin from "@mixins/LoginInfoMixin.vue"
import PartCard from "@components/part/PartCard.vue"
import axios from "axios"
import {Menu} from "ant-design-vue"
export default {
	components : {
		PartCard ,
		"a-menu" : Menu, "a-menu-item" : Menu.Item ,
	} ,
	mixins : [PageMixin,LoginInfoMixin] ,
	page() {
		return {
			title : this.$t('page.title',{name:this.part.sku})
		}
	} ,
	data() {
		return {
			part : {} ,
			selectedMenu : []
		}
	} ,
	watch : {
		$route(newVal) {
			if (!this.part || newVal.params.id != this.part.id) {
				this.fetchData()
			}
			this.updateSelectedMenu()
		}
	} ,
	mounted() {
		this.updateSelectedMenu()
		this.fetchData()
	} ,
	methods : {
		updateSelectedMenu() {
			this.selectedMenu = [this.$route.name]
		} ,
		fetchData() {
			const partId = this.$route.params.id;
			if (!partId)
				return
			this.showPageLoading()
			this.clearBreadcrumbParams('partName')
			axios.get("/api/parts/"+partId).then((response) => {
				this.part = response.data.data.part;

				this.addBreadcrumbParams({partName : this.part.name})
			}).catch((error) => {
				this.fetchError(error);
			}).finally(()=> {
				this.hidePageLoading()
			})
		}
	}
}
</script>
